import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { PowerBiApi } from '../models/power-bi-models';

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {

  http = inject(HttpClient);

  dexmachTenantId = "99c75c69-9ef2-4c4e-b5e3-54b6cf95c977"

  constructor() { }

  getOwnWorkspaces() : Observable<PowerBiApi.Workspace[]> {
    return this.http.get<PowerBiApi.GetWorkspacesResponse>(`https://api.powerbi.com/v1.0/myorg/groups?ctid=${this.dexmachTenantId}`).pipe(
      map((response: any) => {
        return response.value.map((workspace: any) => {
          // add display name to workspace
          let displayName;
          // regex to extract display name from workspace name
          // first remove 00000x_ from the workspace name (beginning)
          // remove -0x from the workspace name (end)
          displayName = workspace.name.replace(/^[0-9]+_/, '').replace(/-[0-9]+$/, '');
          workspace.displayName = displayName;
          workspace.displayName = workspace.displayName.charAt(0).toUpperCase() + workspace.displayName.slice(1);
          return workspace;
        }).sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
      })
    ); 
  }

  getReportsFromWorkspace(workspaceId: string) : Observable<any> {
    return this.http.get<PowerBiApi.GetReportsInWorkspaceResponse>(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports?ctid=${this.dexmachTenantId}`).pipe(
      tap(console.log),
      map((response: PowerBiApi.GetReportsInWorkspaceResponse) => {
        return response.value.sort((a: any, b: any) => a.name.localeCompare(b.name));
      })
    );
  }

  refreshUserPermissions() : Observable<any> {
    return this.http.post(`https://api.powerbi.com/v1.0/myorg/refreshUserPermissions?ctid=${this.dexmachTenantId}`, {});
  }
}
